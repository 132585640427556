<template>
  <v-card>
    <v-dialog
        fullscreen
        v-model="preview"
    >
      <div class="image-wrapper">
        <div class="image-content" v-if="!is_pdf">
          <v-img
              aspect-ratio="1"
              :src="file_url"
              max-height="90vh"
              style="position: relative"
          >
            <v-btn @click="preview = false; is_pdf = false"
                   class="white"
                   depressed
                   style="position: absolute; top: 0; right: 0"
                   :v-ripple="false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-img>
        </div>
      </div>

    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12" class="d-flex">
            <v-select
                :items="voting_types_select"
                v-model="vote_type"
                hide-details
                filled
                label="Тип повідомлення"
                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                :class="vote_type ? '' : 'req-star'"
                class="mr-2"
                color="grey"
            />
            <v-checkbox
                v-model="important"
                class="mr-2"
                hide-details
                label="Важливе"
                color="secondary"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect v-model="buildings"
                                  label="Будинок(-ки)" multiple filled
                                  select_type="building"
            />
          </v-col>
          <v-col cols="12" :md="vote_type === 'INFORMING' || vote_type === null ? 12 : 8">
            <v-text-field type="text" filled label="Тема" v-model="theme"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="theme ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="4" v-if="vote_type !== 'INFORMING' && vote_type !== null">
            <date-component v-model="close_date" label="Дата завершення" req :class_="!close_date ? 'req-star' : ''"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea type="text" filled label="Повідомлення" v-model="text"
                        hide-details
                        rows="2"
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="text ? '' : 'req-star'"
                        color="grey"/>
          </v-col>
          <template v-if="vote_type === 'POLL'">
            <v-col cols="12">
              <v-btn depressed small color="success" class="mr-2" dark @click="addNewQuestion">
                <v-icon left class="mr-2">
                  mdi-plus
                </v-icon>
                Додати варіант відповідді
              </v-btn>
              <v-btn depressed small color="error lighten-1" class="mr-2" dark v-if="selected.length">
                <v-icon left class="mr-2">
                  mdi-delete
                </v-icon>
                Вилучити
              </v-btn>
            </v-col>
            <v-col
                cols="12" md="12"
                v-for="(item, idx) in options"
                :key="idx"
                class="d-flex"
            >
              <v-checkbox
                  v-model="item.selected"
                  class="mr-2"
                  hide-details
                  color="secondary"
                  @change="selectVoteQuesting(item.row_num)"
              />
              <v-text-field type="text" filled label="Варіант відповідді" v-model="item.title"
                            hide-details
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="item.title ? '' : 'req-star'"
                            color="grey"/>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="grey lighten-4">
        <div class="d-flex align-center pa-3">
          <label :for="`my-file-Input-${itemId}`"
                 class="success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small"
                 style="cursor: pointer"
          >
            <v-icon left size="20" dark>mdi-plus</v-icon>
            Новий файл
          </label>
          <v-file-input
              multiple
              hide-input
              hide-details
              :id="`my-file-Input-${itemId}`"
              style="display: none"
              accept="image/*"
              :key="itemId"
              @change="changeFile"
          />
        </div>
        <v-divider></v-divider>
        <div class="d-flex align-center flex-wrap">
          <v-col cols="12" sm="12" md="6" v-for="file in files" :key="`c-file-${itemId}-idx--${file.id}`">
            <v-card>
              <v-list-item>
                <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon size="32" v-if="file" v-on="on" v-bind="attrs">
                        {{ getFileIcon(file.file_ext) }}
                      </v-icon>
                    </template>
                    <span>Попередній перегляд</span>
                  </v-tooltip>

                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                      :style="file.delete ? 'text-decoration: line-through' : ''"
                      class="grey--text"
                      style="cursor: pointer; text-decoration: underline; font-size: .93rem"
                  >
                    <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                       rel="noopener noreferrer" v-if="!file.binary">
                      {{ file.file_name_origin }}
                    </a>
                    <template v-else>
                      {{ file.file_name_origin }}
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle
                      :style="file.delete ? 'text-decoration: line-through' : ''"
                      style="font-size: .8rem"
                  >
                    <template v-if="file.binary">
                      Додано щойно
                    </template>
                    <template v-else>
                      Завантажено: {{ file.create_date | formatDate }}
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" @click.stop="deleteFile(file)">
                    <v-icon color="white">
                      {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="!files.length">
            <div class="empty-files">
              Вкладені файли відсутні. Натисніть "Новий файл"
            </div>
          </v-col>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {VOTE_ITEM_UPDATE, VOTE_ITEM_CREATE, VOTE_ITEM_REMOVE} from "@/store/actions/vote";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {ext_obj, voting_types_select} from "@/utils/icons";
import {maxBy} from "lodash";
import {BASE_URL_AXIOS} from "@/utils/axios";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'voting_modal_delete'

export default {
  name: "HWP_Modal_Position",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      base_url: BASE_URL_AXIOS,
      preview: false,
      file_url: '',
      is_pdf: false,
      vote_type: this.item.vote_type || null,
      theme: this.item.theme || '',
      important: this.item.important || false,
      create_date: this.item.create_date || null,
      closed: this.item.closed || false,
      close_date: this.item.close_date || null,
      text: this.item.text || '',
      buildings: this.item.buildings || [],
      files: this.item.files || [],
      options: this.fillOptions(),
      voting_types_select,
      selected: []
    }
  },
  methods: {
    changeFile(payload) {
      if (!payload) {
        return
      }

      const max_obj = maxBy(this.files, i => i.id)
      const max = max_obj ? (max_obj.id || 0) : 0

      payload.forEach((item, idx) => {
        const file_ext = (ext_obj.find(item_ext => item_ext.mime === item.type)) || {ext: 'txt', mime: ''}
        const file_obj = {
          id: (max || 0) + idx + 19999999,
          file_path: item.name,
          file_ext: file_ext.ext,
          file_name: item.name,
          file_name_origin: item.name,
          binary: item,
        }
        this.files.push(file_obj)
      })
    },
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    deleteFile(payload) {
      if (payload.binary) {
        const obj_idx = this.files.indexOf(payload)
        if (payload) {
          this.files.splice(obj_idx, 1)
        }
      } else {
        payload.delete = !payload.delete
      }
    },
    getFile(payload) {
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, { text: 'Для перегляду спершу слід виконати команду зберегти', color: 'secondary' })
        return '#'
      }

      return `${BASE_URL_AXIOS}/${payload.file_path}`
    },
    preview_image(payload) {
      const this_image = this.imageExt(payload.file_ext)
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, {
          text: 'Для перегляду спершу слід виконати команду зберегти',
          color: 'secondary'
        })
        return;
      }

      if (!this_image) {
        return
      }
      if (payload.file_ext === 'pdf') {
        this.is_pdf = true
      }

      this.file_url = `${BASE_URL_AXIOS}/${payload.file_path}`

      this.preview = true
      //
      // staticAPI.get_file(payload.file_path)
      //     .then(response => {
      //       // const type = response.headers['content-type']
      //       const blob = new Blob([response.data])
      //       this.file_url = window.URL.createObjectURL(blob)
      //     })
    },
    imageExt(ext) {
      const image_types = ext_obj.filter(item => item.mime.indexOf('image/') !== -1)
      const obj = image_types.find(item => item.ext === ext)
      return !!obj
    },
    fillOptions(payload) {
      if (!payload) {
        if (this.item.options) {
          let options = JSON.parse(JSON.stringify(this.item.options)) || []
          return options.map((item, idx) => Object.assign({}, {...item}, { row_num: idx + 1 }))
        } else {
          return []
        }
      } else {
        if (payload) {
          let options = JSON.parse(JSON.stringify(payload)) || []
          return options.map((item, idx) => Object.assign({}, {...item}, { row_num: idx + 1 }))
        } else {
          return []
        }
      }
    },
    selectVoteQuesting(row_num) {
      if (this.selected.includes(row_num)) {
        this.selected.splice(this.selected.indexOf(row_num), 1)
      } else {
        this.selected.push(row_num)
      }
    },
    addNewQuestion() {
      const max_row_item = maxBy(this.options, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }
      const new_obj = {
        positive:false,
        title: '',
        vote_id: this.item.vote_id,
        row_num: max_row + 1
      }
      this.options.push(new_obj)
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.vote_type = this.item.vote_type || null
      this.theme = this.item.theme || ''
      this.important = this.item.important || false
      this.create_date = this.item.create_date || null
      this.closed = this.item.closed || false
      this.close_date = this.item.close_date || null
      this.text = this.item.text || ''
      this.buildings = this.item.buildings || []
      this.options = this.fillOptions()
      this.files = this.item.files || []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення голосування: ${this.theme}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        const error_text = this.vote_type === 'POLL' ? 'Тема та варіанти відоповідей мають бути заповнені' : 'Тема та текст мають бути заповнені'
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = new FormData()
      payload.append("vote_type", this.vote_type)
      payload.append("theme", this.theme)
      payload.append("important", this.important)
      payload.append("closed", this.closed)
      if (this.close_date) {
        const close_date = `${this.close_date}${this.close_date.indexOf('T') === -1 ? 'T00:00:00.000000' : ''}`
        payload.append("close_date", close_date)
      }
      payload.append("text", this.text)
      payload.append("buildings", JSON.stringify(this.buildings))

      if (this.vote_type === 'POLL') {
        payload.append("options", JSON.stringify(this.options))
      }

      if (this.isNew) {
        this.files.forEach(item => {
          if (item.binary) {
            payload.append('files', item.binary)
          }
        })

        this.$store.dispatch(VOTE_ITEM_CREATE, payload)
            .then(el => {
              if (el) {
                try {
                  this.$ws.send_message(Object.assign(el, { ws_type: 'vote_crud' }))
                } catch {
                  this.$store.commit(ALERT_SHOW, {text: 'Щось не так із SOCKET)', color: 'error'})
                } finally {
                  this.closeModal()
                }
              }
            })
      } else {
        payload.append("id_", this.itemId)
        this.files.forEach(item => {
          if (item.binary) {
            payload.append('files', item.binary)
          } else {
            if (item.delete) {
              payload.append('files_delete', item.id)
            }
          }
        })

        this.$store.dispatch(VOTE_ITEM_UPDATE, payload)
            .then(el => {
              if (el) {
                try {
                  this.$ws.send_message(Object.assign(el, { ws_type: 'vote_crud' }))
                } catch {
                  this.$store.commit(ALERT_SHOW, {text: 'Щось не так із SOCKET)', color: 'error'})
                } finally {
                  this.closeModal()
                }
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.vote_type = payload.vote_type || null
              this.theme = payload.theme
              this.important = payload.important || false
              this.create_date = payload.create_date || null
              this.closed = payload.closed || false
              this.close_date = payload.close_date || null
              this.text = payload.text || ''
              this.buildings = payload.buildings || []
              this.options = this.fillOptions(payload.options)
              this.files = payload.files || []
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(VOTE_ITEM_REMOVE, this.itemId)
                    .then(ok => {
                      if (ok) {
                        try {
                          this.$ws.send_message(Object.assign(ok, { ws_type: 'vote_remove' }))
                        } catch {
                          this.$store.commit(ALERT_SHOW, {text: 'Щось не так із SOCKET)', color: 'error'})
                        }
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
  }
}
</script>

<style scoped lang="scss">
.empty-files {
  height: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px dashed #4caf507d;
  font-size: 1rem;
  color: #808080e6;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-content {
    height: 90%;
    width: 90%;
    position: relative;
  }
}
</style>
